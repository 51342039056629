<template>
    <div class="animation fadeIn">
      <div id="snackbar"></div>
      <b-row>
          <b-col sm="12">
              <b-card>
                  <b-form v-on:submit.prevent.self="submit">
                    <b-row>

                      <b-col sm="6">
                        <label for="TemplateTitle">Template Title</label>
                        <b-form-group>
                            <b-form-input type="text" required v-model="templateDef.TemplateTitle" placeholder="Title"></b-form-input>
                        </b-form-group>
                      </b-col>
                       <b-col sm="6">
                         <label for="TemplateIsActive">Status</label>
                        <b-form-group v-if="templateDef">
                          <c-switch class="mx-1" color="primary" v-model="templateDef.TemplateIsActive" defaultChecked variant="3d" label v-bind="labelIcon" />
                        </b-form-group>
                      </b-col>
                    </b-row>

                  
                    <b-row>
                      <b-col>
                        <b-form-group>
                          <label for="TemplateBody">Content</label>
                            <vue-editor v-model="templateDef.TemplateBody"></vue-editor>
                        </b-form-group> 
                      </b-col>                   
                    </b-row>

                    <b-row>
                      <b-col sm="6">
                        <b-form-group>
                          <b-button type="submit" variant="primary" size="sm">
                              <i class="fa fa-comment-o">
                              </i>
                              {{ commontext.submitButton }}
                          </b-button>
                        </b-form-group>
                      </b-col>

                      <b-col sm="6">
                        <b-button @click="resetForm" size="sm" variant="success"><i class="fa fa-dot-circle-o"></i> Reset</b-button>
                    </b-col>
                    </b-row>
                  </b-form>

              </b-card>

            <!-- List of templates -->
            <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
            <div slot="TemplateID" slot-scope="props">
                <i class="fa fa-edit" @click="editTemplate(props.row.TemplateID)"></i>
              </div>
              <div slot="TemplateIsActive" slot-scope="props">
                <c-switch class="mx-1" color="primary" v-model="props.row.TemplateIsActive" variant="3d" label v-bind="labelIcon" @change.native="changeStatus(props.row.TemplateID)"/>             
              </div>
            </v-client-table>
              <!-- End List -->
          </b-col>
      </b-row>
      <b-modal id="ModalConfirmationOnSubmit" ref="ModalConfirmationOnSubmit" @ok="handleModalOkay" :title="modalObject.title" class="center-footer" footer-class="justify-content-center border-0" cancel-variant="outline-danger" ok-variant="outline-success" centered hide-header no-close-on-esc no-close-on-backdrop >
         <div class="d-block text-center">
           <div>{{ modalObject.message }}</div>
         </div>
       </b-modal>
  </div>
</template>
<script>
import { Switch as cSwitch } from "@coreui/vue";
import { VueEditor } from "vue2-editor";
import MasterService from "@/services/MasterService";
import { Event } from "vue-tables-2";
export default {
  name: "Template",
  components: {
    cSwitch,
    VueEditor,
    Event
  },
  data() {
    return {
      commontext: {
        submitButton: "Create"
      },
      templateDef: {
        Flag: 1,
        TemplateID: null,
        TemplateTitle: "",
        // TemplateTypeID: 0,
        TemplateBody: null,
        TemplateIsActive: true
      },
      templateTypes: [],
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      columns: ["TemplateTitle", "TemplateIsActive", "TemplateID"],
      data: [],
      options: {
        headings: {
          TemplateTitle: "Template Title",
          TemplateIsActive: "Status",
          TemplateID: "Edit"
        },
        sortable: ["TemplateTitle"],
        filterable: ["TemplateTitle"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default"
    };
  },
  mounted() {
    // this.getTemplateTypes()
    this.getTemplates();
  },
  methods: {
    resetForm: function() {
      this.templateDef = {
        Flag: 1,
        TemplateID: null,
        TemplateTitle: "",
        // TemplateTypeID: 0,
        TemplateBody: null,
        TemplateIsActive: true
      };
      this.commontext.submitButton = "Create";
    },
    // async getTemplateTypes() {
    //   this.templateTypes = []
    //   let res = await MasterService.getTemplateTypesDDL()
    //   this.templateTypes.push({
    //     text: 'Select Template Type',
    //     value: 0
    //   })
    //   res.data.map(t => {
    //     this.templateTypes.push({
    //       text: t.TemplateTypeName,
    //       value: t.TemplateTypeID
    //     })
    //   })
    // },
    getTemplates: function() {
      this.data = [];
      MasterService.getTemplates()
        .then(response => {
          const { data } = response;
          if (data.Status && data.TemplateList.length > 0) {
            this.data = data.TemplateList.reverse();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    validation: function() {
      if (this.templateDef.TemplateTitle == "") {
        this.showMessageSnackbar("Template Title Cannot be empty.");
        return false;
      } else if (this.templateDef.TemplateBody == "") {
        this.showMessageSnackbar("Template Content Cannot be empty.");
        return false;
      } else {
        return true;
      }
    },
    submit: function() {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      this.closeModal("ModalConfirmationOnSubmit");

      MasterService.addTemplate(this.templateDef)
        .then(response => {
          const { data } = response;
          data.Message ? this.showMessageSnackbar(data.Message) : "";
          if (data.Status) {
            this.getTemplates();
            this.resetForm();
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editTemplate: function(TemplateID) {
      let payload = {
        TemplateID
      };
      MasterService.getTemplateByID(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.TemplateList.length > 0) {
            this.templateDef = data.TemplateList[0];
            this.templateDef.Flag = 2;
            this.commontext.submitButton = "Update Template";
            this.scrollToTop();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    changeStatus: function(ID) {
      let payload = {
        TableName: "Template",
        ID
      };
      MasterService.activateOrDeactivate(payload)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
